<template>
  <div>
    <b-card>
      <div class="title">
        <p>請選擇戲院</p>
      </div>
      <div>
        <b-button
          size="sm"
          variant="outline-secondary"
          class="handle-button mb-2"
          @click="returnRecord()"
        >
          回上頁
        </b-button>
      </div>
      <p>
        <b style="color: #0e72bc">已選擇的日期:</b>
        <b style="color: red">{{ $moment($route.query.date).format() }}</b>
      </p>
      <div v-loading="loading">
        <b-table
          bordered
          class="mt-2"
          striped
          hover
          responsive
          :items="tableData"
          :fields="
            $store.state.highlightCol.firTable
              ? $store.state.newTableFields
              : tableFields
          "
          @head-clicked="
            commonHighLightCol(
              $event,
              tableFields,
              $store.state.newTableFields,
              'firTable'
            )
          "
        >
          <template v-slot:cell(action)="row">
            <b-button
              size="sm"
              class="mr-2"
              @click="goDetail(row.item.id, row.item.full_name)"
            >
              作出修訂
            </b-button>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
// import vSelect from 'vue-select'
import { getBoxOfficesList } from '@/api/editBoxoffice.js'

export default {
  components: {
    // vSelect,
  },
  data() {
    return {
      loading: false,
      rows: 1,
      perPage: 1,
      currentPage: 1,
      select: 'All locations',
      selectOptions: ['All locations', 'Hong Kong', 'Macau'],
      tableData: [],
      tableFields: [
        { key: 'id', label: 'Theatre ID ', sortable: true },
        { key: 'full_name', label: 'Theatre Name', sortable: true },
        // { key: 'xml_fid', label: 'XML ID' },
        { key: 'circuit', label: 'Circuit', sortable: true },
        // { key: 'alert_mail_1', label: '通知電郵或電話' },
        // { key: 'status', label: 'BoxOffice Records' },
        { key: 'action', label: '' }
      ],
      listParams: {
        page: 1,
        count: 10
      }
    }
  },
  methods: {
    returnRecord() {
      this.$router.go(-1)
    },
    getList() {
      this.loading = true
      const data = {
        date:this.$route.query.date
      }
      getBoxOfficesList(data)
        .then((res) => {
          this.tableData = res.data
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    goDetail(id, full_name) {
      const query = {
        id: id || null,
        date: this.$route.query.date,
        name: full_name
      }
      this.$router.push({ name: 'select-film', query })
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  border-bottom: 2px solid #0e72bc;
  color: #0e72bc;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>
